// This file is automatically generated as part of the build process

export const version = {
    version: "0.1.8-beta8708",
    commitInfo: "Branch.develop.Sha.17b6dc4c42c644d02c5d94a3c99e9add29771ed1",
    commitDate: "2024-11-28",
    commitHash: "17b6dc4c42c644d02c5d94a3c99e9add29771ed1",
    informationalVersion: "0.1.8-beta.8708+Branch.develop.Sha.17b6dc4c42c644d02c5d94a3c99e9add29771ed1",
    buildDateUtc: new Date(Date.UTC(2024, 10, 28, 22, 11, 49))
}
